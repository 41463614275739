import LocaleProvider from "../components/LocaleProvider.js";
import MainLayout from "../components/MainLayout.js";
import { members } from "../data/members.js";
import Nav from "../components/Nav.js";
import React from "react";
import Members from "../components/Members.js";
import Section from "../components/Section.js";

const MembersPage = props => {
    return (
        <LocaleProvider>
            <MainLayout>
                <Nav darkMenu={true} />
                <Section
                    sectionProps={{
                        className: "slide whiteSlide member"
                    }}
                >
                    <Members members={members} />
                </Section>
            </MainLayout>
        </LocaleProvider>
    );
};

export default MembersPage;
